<template>
  <section
    v-show="isShow"
    :class="dynamicClass"
    ref="modalOverlay"
    @click.self="hideModal"
  >
    <div class="modal__body" @click.stop ref="modalBody">
      <button class="cross" @click="hideModal"></button>
      <button class="chevron" @click="hideModal">
        <!-- TODO: сейчас проблема прокинуть путь до картинки -->
        <svg v-show="isBottomPosition" class="chevron__icon" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.9543 6.61598C17.7199 6.38164 17.402 6.25 17.0706 6.25C16.7391 6.25
              16.4212 6.38164 16.1868 6.61598L9.99933 12.8035L3.81183 6.61598C3.57608
              6.38829 3.26032 6.26229 2.93258 6.26514C2.60483 6.26799 2.29132 6.39945
              2.05956 6.63121C1.8278 6.86297 1.69633 7.17648 1.69349 7.50423C1.69064
              7.83198 1.81663 8.14773 2.04433 8.38348L9.11558 15.4547C9.34999 15.6891
              9.66787 15.8207 9.99933 15.8207C10.3308 15.8207 10.6487 15.6891 10.8831
              15.4547L17.9543 8.38348C18.1887 8.14907 18.3203 7.83119 18.3203 7.49973C18.3203
              7.16828 18.1887 6.85039 17.9543 6.61598Z"
            fill="#333333"
          />
        </svg>
        <svg v-show="!isBottomPosition" width="24" height="24" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="close-button" d="M25 25L5 5M25 5L5 25" stroke-width="4" stroke-linecap="round"/>
        </svg>
      </button>
      <div
        class="modal__body-container_scroll"
        ref="scrollContainer"
        @touchstart="startSwipe($event)"
        @touchmove="swipe($event)"
        @touchend="stopSwipe"
      >
        <div class="swipe-block">
          <div class="swipe-block__line"></div>
        </div>
        <slot></slot>
      </div>
    </div>
  </section>
</template>

<script>
import bodyState from '@helpers/bodyState'

export default {
  name: 'InitModal',
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
    isBottomPosition: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      isSwiping: false,
      touchStart: 0,
      touchEnd: 0,
      initOverlayColor: '',
    }
  },
  mounted() {
    this.initOverlayColor = window.getComputedStyle(this.modalOverlay).backgroundColor
  },
  methods: {
    closeModal() {
      this.$emit('close', false)
      this.modalBody.removeEventListener('transitionend', this.closeModal)
    },
    hideModal() {
      const distanceToHideModal = +this.modalBody.getBoundingClientRect().bottom
      this.modalBody.style.transition = '0.6s ease'
      this.modalBody.style.transform = `translate3d(0, ${distanceToHideModal}px, 0)`
      this.modalOverlay.style.backgroundColor = this.modifiedRgba(this.initOverlayColor, 0)
      this.modalBody.addEventListener('transitionend', this.closeModal)
    },
    startSwipe($event) {
      if ($event.target.tagName === 'IMG') return
      this.touchStart = $event.targetTouches[0].clientY
      this.modalBody.style.transition = null

      this.$refs.scrollContainer.scrollTop === 0 && (this.isSwiping = true)
    },
    swipe($event) {
      this.touchEnd = $event.targetTouches[0].clientY
      const canSwiping = () => this.isSwiping && this.touchStart < this.touchEnd && $event.cancelable

      if (canSwiping()) {
        $event.preventDefault()
        this.modalBody.style.transform = `translate3d(0, ${this.touchEnd - this.touchStart}px, 0)`
        this.changeModalOverlayContrast()
      }
    },
    stopSwipe() {
      this.modalBody.style.transition = '0.3s ease'
      const DISTANCE_TO_CLOSE = 100

      if (this.isSwiping && this.touchEnd - this.touchStart > DISTANCE_TO_CLOSE) {
        this.hideModal()
      } else {
          this.modalBody.style.transition = '0.3s ease'
          this.modalBody.style.transform = 'translate3d(0, 0, 0)'
      }

      this.isSwiping = false
      this.touchStart = 0
      this.touchEnd = 0
    },
    resetModalStyles() {
      this.modalOverlay.style.backgroundColor = this.initOverlayColor
      this.modalBody.style.transform = `translate3d(0, 0, 0)`
    },
    changeModalOverlayContrast() {
      const windowScale = +this.modalBody.getBoundingClientRect().top / +window.innerHeight
      const maxRgbaAlpha = +this.alphaValueFromRgba(this.initOverlayColor)
      const currentRgbaAlpha = maxRgbaAlpha - maxRgbaAlpha * windowScale

      this.modalOverlay.style.backgroundColor = this.modifiedRgba(this.initOverlayColor, currentRgbaAlpha)
    },
    alphaValueFromRgba(rgba) {
      return rgba.replace(/[^\d,.]/g, '').split(',')[3]
    },
    modifiedRgba(rgba, alpha) {
      const rgbaArr = rgba.replace(/[^\d,]/g, '').split(',')
      rgbaArr[3] = +alpha.toFixed(2)
      return `rgba(${rgbaArr.join(',')})`
    },
  },
  watch: {
    isShow(isOpen) {
      if (isOpen) {
        bodyState.blockBody()
        this.resetModalStyles()
      } else {
        bodyState.unblockBody()
      }
    },
  },
  computed: {
    dynamicClass() {
      return [
        'modal',
        {
          'modal_bottom': this.isBottomPosition,
          'show': this.isShow,
        },
      ]
    },
    modalBody() {
      return this.$refs.modalBody
    },
    modalOverlay() {
      return this.$refs.modalOverlay
    },
  },
}
</script>
