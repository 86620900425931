<template>
  <VueDatePicker
    class="app-datepicker"
    v-model="date"
    :color="mainColor"
    no-header
    placeholder="Выберите дату"
    :min-date="minDate"
    :max-date="maxDate"
    fullscreen-mobile
    :name="label"
    mobile-breakpoint="425"
  >
    <template v-slot:activator="{ date }">
      <app-input
        :label="label"
        ref="activator"
        type="button"
        :value="replaceDate"
        :is-required="isRequired"
      >
      </app-input>
    </template>
  </VueDatePicker>
</template>
<script>
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'
import appInput from '@ui/app-input/appInput.vue'
import dateToKebabCase from '../../utils/dateFormat.js'

export default {
  name: 'AppDatepicker',
  components: { VueDatePicker, appInput },
  emits: ['select'],
  props: {
    minDate: {
      type: [Date, String],
      default: dateToKebabCase(new Date()),
    },
    maxDate: {
      type: [Date, String],
    },
    label: {
      type: String,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    initialDate: {
      type: [Date, String],
      default: null,
    },
  },
  data() {
    return {
      date: this.initialDate || this.minDate,
    }
  },
  computed: {
    mainColor() {
      // принимает только hex, переменные не распознает
      return '#333333'
    },
    replaceDate() {
      const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }

      const today = dateToKebabCase(new Date())
      const selectedDate = dateToKebabCase(this.date)

      return !this.date || (today === selectedDate) ? 'Сегодня' : new Date(this.date).toLocaleDateString(undefined, options)
    }
  },
  watch: {
    date() {
      this.$emit('select', this.date)
    },
    initialDate() {
      this.date = this.initialDate
    },
  },
  mounted() {
    if (!this.date) {
      this.date = dateToKebabCase(new Date())
    }
  }
}
</script>
