// eslint-disable-next-line no-undef
// Эти строки используются для отмены линтинга необъявленной переменной Routing

import Vue from 'vue'
import {
    mapActions,
    mapGetters,
    mapMutations,
    mapState,
} from 'vuex'
import autosize from 'autosize'
import VueAwesomeSwiper from 'vue-awesome-swiper'

import router from './router'
import store from './store'

import * as mutations from './store/types/mutations-types'
import * as actions from './store/types/actions-types'

import {
    getOptionsFilter,
    getPriceFilter,
    setFilters,
    setOptionFilter,
    setPriceFilter,
} from './browser/filters'

import localStorageCleaner from './utils/localStorageCleaner'
import {
    convertDateTimeToString,
    getTwoWeekFutureDateString,
    getTwelveYearsAgoDateString
} from '@utils/convertDate'
import { currentWordEndings } from '@utils/currentWordEndings'
import { bonusEndings, productEndings } from '@utils/locales'

import imageCropper from './components/imageCropper.vue'
import slidePreview from './components/slidePreview.vue'
import slider from './components/slider.vue'

import './base.scss'

import appInput from './ui/app-input/appInput.vue'
import appButton from './ui/app-button/appButton.vue'
import appCounter from './ui/app-counter/appCounter.vue'
import appRadio from './ui/app-radio/appRadio.vue'
import appSelect from './ui/app-select/appSelect.vue'
import appCardButton from './ui/app-card-button/appCardButton.vue'
import appTextarea from './ui/app-textarea/appTextarea.vue'
import appAccordion from './components/appAccordion.vue'
import appCheckbox from './ui/app-checkbox/appCheckbox.vue'
import appHorizontalScroll from './ui/app-horizontal-scroll/appHorizontalScroll.vue'
import approveModal from './ui/app-modals/approve-modal/approveModal.vue'
import authModal from './ui/app-modals/auth-modal/authModal.vue'
import appDatepicker from './ui/app-datepicker/appDatepicker.vue'
import addressModal from './ui/app-modals/address-modal/addressModal.vue'
import appSidebar from './ui/app-sidebar/appSidebar.vue'
import appAutocomplete from './ui/app-autocomplete/appAutocomplete.vue'

import { createOrder } from './api/shop'

import { DELIVERY_TYPES, NO_DELIVERY } from './constants'

import productModal from './ui/app-modals/product-modal/productModal.vue'

import fullStreetTemp from '@/mixins/fullStreetTemp'
import ShowToast from './utils/showToast'

Vue.use(VueAwesomeSwiper)

localStorageCleaner()

Vue.options.delimiters = ['{!', '!}']

Vue.prototype.screenBodyWidth = window.innerWidth
Vue.prototype.tokenDaData = ''


const vueApp = new Vue({
    el: '#vue-app',
    store,
    router,
    mixins: [fullStreetTemp],
    data: () => ({
        isFormValid: false,
        errors: {
            orderDetails: {
                name: false,
                phone: false,
                deliveryAddress: false,
                pickupAddress: false,
            }
        },
        imgPath: '',
        slideLinkType: 'product',
        profile: {
            name: '',
            birthday: '',
            email: '',
            gender: '',
            addresses: [],
            selectedAddressId: '',
            deliveryName: '',
            cards: [],
            selectedCardId: '',
        },
        selectedModifierIds: [],
        isVueLoaded: true,
        isVisibleModals: {
            approveModal: false,
            addressModal: false,
            authModal: false,
            productModal: false,
        },
        isVisibleSidebars: {
          left: false,
          right: false,
        },
        showBackButton: false,
    }),

    components: {
        imageCropper,
        slidePreview,
        slider,
        appAccordion,
        appInput,
        appButton,
        appCounter,
        appRadio,
        appTextarea,
        appCheckbox,
        appSelect,
        appHorizontalScroll,
        approveModal,
        authModal,
        productModal,
        appCardButton,
        addressModal,
        appDatepicker,
        appSidebar,
        appAutocomplete,
    },
    computed: {
        ...mapState({
            products: state => state.products,
            initProducts: state => state.initProducts,
            basket: state => state.basket,
            lastOrderId: state => state.customer.lastOrderId,
            httpInProgress: state => state.httpInProgress,
            questions: state => state.questions,
            cities: state => state.cities,
            city: state => state.customer.city,
            cityExact: state => state.cityExact,
            optionsVariants: state => state.filters.options,
            minPrice: state => state.filters.price.min,
            maxPrice: state => state.filters.price.max,
            croppedImage: state => state.croppedImage,
            slideLinkOptions: state => state.slideLinkOptions,
            discountPromoCode: state => state.customer.discountPrice,
            promoCode: state => state.promoCode,
            deliveryAddress: state => state.deliveryAddress,
            isAddressChosen: state => state.orderDetails.isAddressChosen,
            customer: state => state.customer,
            orderDetails: state => state.orderDetails,
            feedback: state => state.feedback,
            authenticatorData: state => state.authenticatorData,
            isAuthenticated: state => state.customer.isAuthenticated,
            bonusSystem: state => state.bonusSystem,
            pickupPoints: state => state.pickupPoints,
            deliveryIntervals: state => state.deliveryIntervals,
            deliveryType: state => state.deliveryType,
            noDelivery: () => NO_DELIVERY,
            selectedPickupPoint: state => state.selectPickupPoint,
        }),
        ...mapGetters({
            totalBasketSum: 'totalSum',
            basketSum: 'basketSum',
            discountOfBasket: 'discountOfBasket',
            basketTotalQuantity: 'basketTotalQuantity',
            deliveryPrice: 'calculateDeliveryPrice',
            draftQuantity: 'draftQuantity',
            draftSum: 'draftSum',
            isCustomerHaveBonuses: 'isCustomerHaveBonuses',
            customerBonusesAvailableToUse: 'calculateBonusesAvailableToUse',
            maxAmountBonusesToUse: 'maxAmountBonusesToUse',
            discriminatedDraftQuantity: 'discriminatedDraftQuantity',
            discriminatedDraftTotalSum: 'discriminatedDraftTotalSum',
            availableParameters: 'availableParameters',
            idListFromBasketWithoutModifiers: 'idListFromBasketWithoutModifiers',
            quantityOfProductVariant: 'quantityOfProductVariant',
            isCourierPayment: 'isCourierPayment',
            rewardBonuses: 'calculateRewardBonuses',
            remainingAmount: 'calculateRemainingAmount',
        }),
        totalSum: {
            get() { return this.totalBasketSum(this.orderDetails.paidByBonuses) }
        },
        discountAmount: {
            get() { return this.discountOfBasket(this.orderDetails.paidByBonuses) }
        },
        filterPriceFrom: {
            get() { return this.$store.state.filters.active.price.from },
            set(v) { this.$store.commit(mutations.UPDATE_FILTER_FROM_PRICE, v) },
        },
        filterPriceTo: {
            get() { return this.$store.state.filters.active.price.to },
            set(v) { this.$store.commit(mutations.UPDATE_FILTER_TO_PRICE, v) },
        },

        promoCodeName: {
            get() { return this.$store.state.promoCode.name },
            set(v) { this.$store.commit(mutations.UPDATE_PROMOCODE, v) },
        },
        feedbackName: {
            get() { return this.$store.state.feedback.name },
            set(v) { this.$store.commit(mutations.UPDATE_FEEDBACK_NAME, v) },
        },
        feedbackEmail: {
            get() { return this.$store.state.feedback.email },
            set(v) { this.$store.commit(mutations.UPDATE_FEEDBACK_EMAIL, v) },
        },
        feedbackPhone: {
            get() { return this.$store.state.feedback.phone },
            set(v) { this.$store.commit(mutations.UPDATE_FEEDBACK_PHONE, v) },
        },
        feedbackMessage: {
            get() { return this.$store.state.feedback.message },
            set(v) { this.$store.commit(mutations.UPDATE_FEEDBACK_MESSAGE, v) },
        },
        paidByBonusesLocal: {
            get() { return this.$store.state.authenticatorData.paidByBonusesLocal },
            set(paidByBonuses) {
                this.$store.commit(mutations.UPDATE_PAID_BY_BONUSES_LOCAL, {paidByBonuses: Number(paidByBonuses), bonusesAvailableToUse: this.customerBonusesAvailableToUse})
            },
        },
        customerDeliveryInterval: {
            get() { return this.$store.state.customer.delivery.interval },
            set(v) { this.$store.commit(mutations.UPDATE_ORDER_DETAILS_DELIVERY_INTERVAL, v) },
        },
        //  TODO: проверить нужно ли это вообще
        // customerPickupPoint: {
        //     get() { return this.$store.state.customer.delivery.pickupPoint },
        //     set(v) {
        //         if (v) {
        //             if (this.isPickupMapModalExist()) {
        //                 let iFrame2 = document.getElementById('pickup-iframe-map-modal')
        //                 iFrame2.contentWindow.postMessage(v ? v.apiId : null, '*')
        //             }
        //             if (this.isPickupMapCreateOrder()) {
        //                 let iFrame = document.getElementById('pickup-iframe-map-create-order')
        //                 iFrame.contentWindow.postMessage(v ? v.apiId : null, '*')
        //             }
        //             this.$store.commit(mutations.UPDATE_CUSTOMER_DELIVERY_PICKUP_POINT, v)
        //         } else {
        //             this.$store.commit(mutations.UPDATE_CUSTOMER_DELIVERY_PICKUP_POINT, this.$store.state.customer.delivery.pickupPoint)
        //         }
        //     },
        // },
        localPickupPoint: {
            get() { return this.$store.state.selectPickupPoint },
            set(v) {
                if (this.isPickupMapModalExist()) {
                    let iFrame2 = document.getElementById('pickup-iframe-map-modal')
                    iFrame2.contentWindow.postMessage(v ? v.apiId : null, '*')
                }
                this.$store.commit(mutations.UPDATE_PICKUP_POINTS_SELECTED, v)
            },
        },

        deliveryTypeRadioList() {
            return [
                {
                    groupName: 'deliveryType',
                    value: DELIVERY_TYPES.COURIER,
                    label: 'Доставка',
                    isDefaultChecked: this.deliveryType === DELIVERY_TYPES.COURIER,
                },
                {
                    groupName: 'deliveryType',
                    value: DELIVERY_TYPES.PICKUP,
                    label: 'Самовывоз',
                    isDefaultChecked: this.deliveryType !== DELIVERY_TYPES.COURIER,
                },
            ]
        },
        genderClientRadioList() {
            return [
                {
                    groupName: 'gender',
                    value: 'female',
                    label: 'Женский',
                    isDefaultChecked: "this.profile.gender !== 'male'"
                },
                {
                    groupName: 'gender',
                    value: 'male',
                    label: 'Мужской',
                    isDefaultChecked: "this.profile.gender === 'male'"
                }
            ]
        },
        currentDeliveryIntervals() {
            return this.deliveryIntervals
                .find(item => item[0]?.date === this.orderDetails.delivery.date) ?? []
        },
        isEmptyDeliveryIntervals() {
            return this.currentDeliveryIntervals.length === 0
        },
        constants() {
            return {
                PHONE_SCREEN_SIZE: 480,
            }
        },
        currentSelectedAddress() {
            if (!this.orderDetails.isAddressChosen) {
                return 'Указать адрес'
            }
            if (this.deliveryType === DELIVERY_TYPES.COURIER) {
                return this.orderDetails.delivery.address.fullStreet
                    ? this.orderDetails.delivery.address.fullStreet
                    : 'Указать адрес'
            }
            if (this.deliveryType === DELIVERY_TYPES.PICKUP) {
                return this.orderDetails.delivery.pickup !== null
                    ? this.orderDetails.delivery.pickup.name
                    : 'Указать адрес'
            }
        },

        payToCourierOrCashier() {
            return this.deliveryType === DELIVERY_TYPES.COURIER ? 'Курьеру' : 'Кассиру'
        },

        twelveYearsAgoDate() {
            return getTwelveYearsAgoDateString()
        },

        twoWeekString() {
            return getTwoWeekFutureDateString()
        },

        defaultSelectedPickupPoint() {
            return this.selectedPickupPoint?.option || {}
        },

        defaultSelectedDeliveryInterval() {
            return this.orderDetails.delivery.interval || {}
        },

        isDisabledButtonFromOrder() {
            return (this.remainingAmount > 0 || this.isEmptyDeliveryIntervals)
        },
        isMainPage() {
            return this.$route.path === '/'
        },
    },
    methods: {
        ...mapMutations({
            incrementVariantQuantity: mutations.INCREMENT_VARIANT_QUANTITY,
            decrementVariantQuantity: mutations.DECREMENT_VARIANT_QUANTITY,
            clearBasket: mutations.CLEAR_BASKET,
            removeVariant: mutations.REMOVE_FROM_BASKET,
            clearProducts: mutations.CLEAR_PRODUCTS,
            clearLoadedAddresses: mutations.CLEAR_LOAD_ADDRESSES,
            setSlideLinkOptions: mutations.SET_SLIDE_LINK_OPTIONS,
            clearPromoCode: mutations.CLEAR_PROMOCODE,
            clearAuthRequest: mutations.CLEAR_AUTH_REQUEST,
            clearAuth: mutations.CLEAR_AUTH,
            setPaidByBonusesInCustomer: mutations.SET_PAID_BY_BONUSES_IN_CUSTOMER,
            setIsUsingShopLogic: mutations.SET_IS_USING_SHOP_LOGIC,
            openModal: mutations.OPEN_MODAL,
            closeModal: mutations.CLOSE_MODAL,
            resetImagePosition: mutations.RESET_IMAGE_POSITION,
            resetActiveToggle: mutations.RESET_ACTIVE_TOGGLE,
            resetActivePriceVariant: mutations.RESET_ACTIVE_PRICE_VARIANT,
            resetSelectedModifiers: mutations.RESET_SELECTED_MODIFIERS,
            setProduct: mutations.SET_PRODUCT,
            selectGroupModifier: mutations.SELECT_GROUP_MODIFIER,
            setDraft: mutations.SET_DRAFT,
            setAmount: mutations.SET_AMOUNT,
            setImagePath: mutations.SET_IMAGE_PATH,
            setMainImage: mutations.SET_MAIN_IMAGE,
            resetActiveImage: mutations.RESET_ACTIVE_IMAGE,
        }),

        updatePromoCode() {
            store.commit(mutations.UPDATE_PROMOCODE, this.customer.promoCode)
            this.getPromoCode()
        },

        setLoadedAddressOnDeliveryAndPayment (loadedAddress) {
            store.commit(mutations.SET_LOADED_ADDRESS_IN_ADDRESS, loadedAddress)
        },

        setDeliveryPrice() {
            const { fullStreet } = this.deliveryAddress.address

            if (fullStreet) {
                store.dispatch(actions.LOAD_DELIVERY_PRICES)
            }
        },
        setLoadAddressForDeliveryPrice(loadedAddress) {
            store.commit(mutations.SET_LOADED_ADDRESS_IN_ADDRESS, loadedAddress)
            store.commit(mutations.CLEAR_LOAD_ADDRESSES)
        },
        updateCity(city) {
            store.commit(mutations.UPDATE_CUSTOMER_CITY, { city, exact: true })
        },
        toggleOptionFilter(key, value) {
            store.commit(mutations.TOGGLE_FILTER_OPTION, { key, value })
        },

        changeDraftParameter(e, id, key) {
            const { value } = e.target
            store.commit(mutations.CHANGE_DRAFT_PRODUCT_PARAMETER, { id, key, value })
        },
        changeDiscriminatedParameter(e, id, key, discriminator) {
            const { value } = e.target
            store.commit(
                mutations.CHANGE_DRAFT_DISCRIMINATED_PARAMETER,
                {
                    id, key, value, discriminator,
                },
            )
        },

        incrementDraftQuantity(id, price) {
            store.dispatch(
                actions.CHANGE_DRAFT_QUANTITY,
                id,
                1,
                price,
            )
        },
        decrementDraftQuantity(id, price) {
            store.dispatch(
                actions.CHANGE_DRAFT_QUANTITY,
                id,
                -1,
                price,
            )
        },
        incrementDiscriminatedQuantity(id, price, discriminator) {
            store.dispatch(
                actions.CHANGE_DISCRIMINATED_QUANTITY,
                id,
                1,
                price,
                discriminator,
            )
        },
        decrementDiscriminatedQuantity(id, price, discriminator) {
            store.dispatch(
                actions.CHANGE_DISCRIMINATED_QUANTITY,
                id,
                -1,
                price,
                discriminator,
            )
        },
        // urls
        goToProfileOrderId(key) {
            window.location.href = Routing.generate('profile_order', { 'id': key })
        },
        // Профиль
        setGenderClient(value) {
            this.profile.gender = value
        },

        changeProfileDeliveryName(option) {
            this.profile.deliveryName = option.name
        },

        setFavoriteCard(card) {
            this.profile.selectedCardId = card.id
        },

        setFavoriteAddress(address) {
            this.profile.selectedAddressId = address.id
        },

        setProfileBirthday($event) {
            this.profile.birthday = $event
        },

        // табы на странице профиля
        handleTabClick(activeTabName) {
            const tabContent = document.querySelectorAll('.profile__content-item')
            const tabLinks = document.querySelectorAll('.profile__navigation-tab')
            const activeContent = document.getElementById(activeTabName)
            const pageTitle = document.querySelector('.profile__title')

            for (let i = 0; i < tabLinks.length; i++) {
                tabContent[i].style.display = 'none'
                tabLinks[i].classList.remove('profile__navigation-tab_active')
            }

            switch (activeTabName) {
                case 'profile_account':
                    pageTitle.innerHTML = 'Профиль'
                    break
                case 'profile_orders':
                    pageTitle.innerHTML = 'Заказы'
                    break
                case 'profile_bonuses':
                    pageTitle.innerHTML = 'Бонусы'
                    break
                default:
                    pageTitle.innerHTML = 'Профиль'
            }
            activeContent.style.display = 'block'
            // eslint-disable-next-line no-restricted-globals
            event.target.classList.add('profile__navigation-tab_active')
        },

        handleSaveProfileData(obj) {
            // eslint-disable-next-line no-restricted-globals
            event.preventDefault()
            const sendingStatus = document.getElementById('account-send-data-btn')
            sendingStatus.classList.add('account-form__send-form-status_success')
            sendingStatus.innerHTML = 'Сохранено'
            setTimeout(() => {
                sendingStatus.innerHTML = ''
                sendingStatus.classList.remove('account-form__send-form-status_success')
            }, 30000)
        },

        setLoadedAddressInCustomer(loadedAddress, isCreateOrderPage) {
            const { city, street, house } = this.deliveryAddress
            store.commit(mutations.SET_LOADED_ADDRESS_IN_ADDRESS, loadedAddress)

            if (isCreateOrderPage) {
                if (city !== '' && house !== '' && street !== '') {
                    store.dispatch(actions.CHOOSE_ADDRESS_COURIER).then(
                        () => {
                            store.dispatch(actions.LOAD_DELIVERY_PRICES)
                        },
                    )
                }
            } else {
                store.dispatch(actions.LOAD_DELIVERY_PRICES)
            }
            store.commit(mutations.CLEAR_LOAD_ADDRESSES)
        },

        openApproveModal() {
            this.isVisibleModals.approveModal = true
        },

        closeApproveModal() {
            this.isVisibleModals.approveModal = false
        },

        openAuthModal() {
            this.isVisibleModals.authModal = true
        },

        closeAuthModal() {
            this.isVisibleModals.authModal = false
        },

        // модалка Выбор Адреса
        openModalAddress() {
            this.isVisibleModals.addressModal = true
        },

        closeModalAddress() {
            this.isVisibleModals.addressModal = false
        },

        openProductModal(slug) {
            // eslint-disable-next-line no-undef
            const {path, query} = slug
            const url = Routing.generate('product', { slug: query.q, _format: 'json' })
            this.setImagePath(this.imgPath)
            this.resetActiveImage()

            fetch(url)
                .then(response => response.json())
                .then(product => {
                    this.setMainImage(product.images[0])
                    this.setProduct(product)
                    // Нужно улучшить код с условиями.
                    // Он нужен, чтобы не было ошибок, если элементов variants не существует
                    this.setAmount((product.variants[0] === undefined)
                        ? 0 : product.variants[0].amount)
                    this.selectGroupModifier((product.variants[0] === undefined)
                        ? {} : product.variants[0].groupModifiers[0])
                    this.setDraft({
                        productId: product.id,
                        parameters: [(product.variants[0] === undefined ? '' : product.variants[0].parameters[0])],
                    })
                })
                .then(() => {
                    this.isVisibleModals.productModal = true
                })
        },

        closeProductModal() {
            this.isVisibleModals.productModal = false
        },

        getFormattedDate(date) {
            return convertDateTimeToString(date)
        },

        changeDeliveryName(option, isCheckoutPage = false) {
            store.commit(
                mutations.UPDATE_PICKUP_POINTS_SELECTED,
                { option },
            )
            if (isCheckoutPage) {
                this.chooseAddressPickup()
            }
        },

        changeDeliveryInterval(interval) {
            store.commit(mutations.UPDATE_ORDER_DETAILS_DELIVERY_INTERVAL, interval)
        },

        setOrderPaymentType(type) {
            this.orderDetails.paymentType = type
        },

        ...mapActions({
            requestAuthCode: actions.REQUEST_AUTH_CODE,
            sendAuthCode: actions.CHECK_CODE,
            getPromoCode: actions.LOAD_PROMOCODE,
            changeDeliveryType: actions.CHOOSE_DELIVERY_TYPE,
            chooseAddressCourier: actions.CHOOSE_ADDRESS_COURIER,
            chooseAddressPickup: actions.CHOOSE_ADDRESS_PICKUP,
            getCurrentBuyerBonuses: actions.LOAD_BUYER_BONUSES,
        }),

        addToBasket(productId) {
            this.setDraft({ productId: productId, parameters: [] })
            store.dispatch(
                actions.ADD_TO_BASKET,
                { currentProductId: productId, currentModifierIds: this.selectedModifierIds },
            )
        },

        logoutProfile() {
            store.dispatch(actions.LOGOUT_USER)
                .then(() => {
                    this.closeApproveModal()
                    window.location.href = Routing.generate('homepage')
                })
        },

        fillDetailsFromAuth(){
            this.orderDetails.name = this.isAuthenticated ? this.customer.rawName : ''
            this.orderDetails.phone = this.isAuthenticated ? this.customer.phone : ''
        },

        setOrderDeliveryType(value) {
            this.changeDeliveryType(value)
            this.isFormValid = false
        },

        handleDateSelect(date) {
            store.commit(mutations.UPDATE_ORDER_DETAILS_DELIVERY_DATE, date)
        },

        addSkeletonToCards() {
            const variantCardsImage = document.querySelectorAll('.card__image')

            if (variantCardsImage) {
                variantCardsImage.forEach(imageWrap => {
                    imageWrap.classList.add('skeleton-animation')

                    if (this.screenBodyWidth > this.constants.PHONE_SCREEN_SIZE) {
                        imageWrap.style.height = `${imageWrap.offsetWidth}px`
                    }
                })
            }
        },

        updateOrderFormValidation(label, event) {
            this.$set(this.errors.orderDetails, label, event)
        },

        isValidFormOrder() {
            const orderDetails = this.errors.orderDetails
            const deliveryType = this.deliveryType

            if (deliveryType === DELIVERY_TYPES.COURIER) {
                return orderDetails.name && orderDetails.phone && orderDetails.deliveryAddress
            } else if (deliveryType === DELIVERY_TYPES.PICKUP) {
                return orderDetails.name && orderDetails.phone && orderDetails.pickupAddress
            }

            return false
        },

        sendOrder() {
            if (!this.isValidFormOrder()) {
                const orderTitle = document.querySelector('.client-data__title')

                if (orderTitle) {
                    orderTitle.scrollIntoView({ behavior: 'smooth' })
                }
                ShowToast('Заполните обязательные поля', 'error')

                return this.isFormValid = !this.isValidFormOrder()
            }
            store.dispatch(actions.CREATE_ORDER).then(r => {
                this.errors.orderDetails = {
                    name: false,
                    phone: false,
                    deliveryAddress: false,
                    pickupAddress: false,
                }
                this.isFormValid = false
            })
        },

        // TODO: Переделать sendFeedback, так как он сейчас отправляется в заказы
        sendFeedback() {
            createOrder({ customer: this.customer })
                .then((res) => {
                    Vue.toasted.show(
                        'Обращение принято. Мы свяжемся с вами.',
                        { duration: 5000 })

                    store.commit(mutations.CLEAR_FEEDBACK_FORM)
                    window.location.href = Routing.generate('homepage')

                })
                .catch((err) => {
                    // TODO: сделано для нас, на релизе надо будет обработать
                    Vue.toasted.show(
                        'Ошибка!',
                        { duration: '2000' },
                    )
                })

        },
        setPriceFilter() {
            const from = Number(this.$store.state.filters.active.price.from)
            const to = this.$store.state.filters.active.price.to
                || 0 || this.$store.state.filters.price.max

            setPriceFilter({ from, to })
        },
        setOptionFilter() {
            const options = this.$store.state.filters.active.options
                .filter(option => option.values.length)
            setOptionFilter(options)
        },
        clearFilters() {
            setFilters({
                from: this.$store.state.filters.price.min,
                to: this.$store.state.filters.price.max,
            }, [])
        },
        setFilters() {
            const { from, to } = this.$store.state.filters.active.price
            const options = this.$store.state.filters.active.options
                .filter(option => option.values.length)

            setFilters({ from, to }, options)
        },
        optionFilterIsActive(key, value) {
            const activeOption = this.$store.state.filters.active.options
                .find(option => option.key === key.toLowerCase())

            return activeOption
                ? !!activeOption.values.find(v => v === value.toLowerCase())
                : false
        },

        isSlideExist() {
            return !!document.getElementById('slide-id')
        },

        isSliderExist() {
            return !!document.getElementById('slider-json')
        },

        isCropperExist() {
            return !!document.getElementById('slide-cropped-image')
        },

        isPickupMapModalExist() {
            return !!document.getElementById('pickup-iframe-map-modal')
        },

        isPickupMapCreateOrder() {
            return !!document.getElementById('pickup-iframe-map-create-order')
        },

        previewSlide() {
            this.$refs.imageCropper.updateCroppedImg()
            const title = document.getElementById('slide-title').value
            const subtitle = document.getElementById('slide-subtitle').value
            const buttonTitle = document.getElementById('slide-button-title').value
            const titleColor = document.getElementById('slide-title-color').value
            const subtitleColor = document.getElementById('slide-subtitle-color').value
            const align = document.getElementById('slide-description-align').value

            const slidePreviewLoc = {
                title,
                subtitle,
                buttonTitle,
                titleColor,
                subtitleColor,
                align,
            }

            store.commit(mutations.SET_SLIDE_PREVIEW, slidePreviewLoc)
        },

        createSlide() {
            let croppedImage
            let croppedImageData
            try {
                croppedImage = this.$refs.imageCropper.cropImage()
                croppedImageData = JSON.stringify(this.$refs.imageCropper.getData())
            } catch (e) {
                Vue.toasted.show('Не удалось обрезать изображение', { duration: 3000, type: 'error' })
                return
            }
            const croppedImageOrigin = this.$store.state.cropped.imageOrigin

            const id = this.isSlideExist() ? document.getElementById('slide-id').dataset.slideId : undefined

            const active = document.getElementById('slide-status').checked
            const title = document.getElementById('slide-title').value
            const subtitle = document.getElementById('slide-subtitle').value
            const buttonTitle = document.getElementById('slide-button-title').value
            const titleColor = document.getElementById('slide-title-color').value
            const subtitleColor = document.getElementById('slide-subtitle-color').value
            const position = document.getElementById('slide-position').value
            let relationType = document.getElementById('slide-button-link-type').value

            let descriptionAlignRight = false
            let descriptionAlignBottom = false
            switch (document.getElementById('slide-description-align').value) {
                case 'top-left':
                    break
                case 'top-right':
                    descriptionAlignRight = true
                    break
                case 'bottom-left':
                    descriptionAlignBottom = true
                    break
                case 'bottom-right':
                    descriptionAlignRight = true
                    descriptionAlignBottom = true
                    break
                default:
                    break
            }
            const style = JSON.stringify({
                descriptionAlignRight,
                descriptionAlignBottom,
            })

            const slideDataJson = {
                id,
                croppedImage,
                croppedImageOrigin,
                croppedImageData,
                active,
                title,
                subtitle,
                buttonTitle,
                titleColor,
                subtitleColor,
                position,
                style,
            }

            if (document.getElementById('slide-button-link-object')) {
                if (buttonTitle) {
                    slideDataJson.entity = { id: document.getElementById('slide-button-link-object').value } // id Article, ...
                } else {
                    relationType = 'custom'
                    slideDataJson.slideLinkUrl = ''
                }
            } else {
                slideDataJson.slideLinkUrl = document.getElementById('slide-button-custom-link').value
            }

            const routeName = id ? 'update_slide' : 'create_slide'
            // eslint-disable-next-line no-undef
            fetch(Routing.generate(routeName, { type: relationType }), {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(slideDataJson),
            }).then(() => {
                // eslint-disable-next-line no-undef
                window.location.href = Routing.generate('slide_list')
            }).catch(error => {
                Vue.toasted.show('Не удалось сохранить слайд', { duration: 3000, type: 'error' })
                console.error(error)
            })
        },

        deleteSlide(id) {
            // eslint-disable-next-line no-undef
            fetch(Routing.generate('delete_slide', { id }), {
            }).then(() => {
                document.getElementById(`slide-of-list${id}`).classList.add('hide')
            }).catch(error => {
                console.error(error)
            })
        },

        updateSlideLinkOptions(id) {
            const type = document.getElementById('slide-button-link-type').value
            this.slideLinkType = type

            if (!this.slideLinkOptions[type]) {
                if (type !== 'custom') {
                    let routeName = ''
                    switch (type) {
                        case 'product':
                            routeName = 'get_products'
                            break
                        case 'article':
                            routeName = 'get_articles'
                            break
                        case 'articleType':
                            routeName = 'get_article_types'
                            break
                        case 'tag':
                            routeName = 'get_tags'
                            break
                        case 'brand':
                            routeName = 'get_brands'
                            break
                        default:
                            throw new Error('updateSlideLinkOptions: Неизвестный класс слайда')
                    }
                    // eslint-disable-next-line no-undef
                    fetch(Routing.generate(routeName, { currentSlideId: id }), {})
                        .then(response => (response.json()))
                        .then(entities => {
                            const slideLinkOptionList = entities.reduce(
                                (acc, item) => { acc[item.id] = item.title; return acc },
                                {},
                            )
                            store.commit(
                                mutations.SET_SLIDE_LINK_OPTIONS,
                                { [type]: slideLinkOptionList },
                            )
                        })
                        .catch(error => (console.error(error)))
                } else {
                    const slideButtonUrl = document.getElementById('slide-cropped-image').dataset.slideUrl
                    if (slideButtonUrl) {
                        store.commit(mutations.SET_SLIDE_LINK_OPTIONS, { custom: slideButtonUrl })
                    }
                }
            }
        },

        getCurrentProductDeclension(value) {
           return currentWordEndings(value, productEndings)
        },

        getCurrentBonusesDeclension(value) {
            return currentWordEndings(value, bonusEndings)
        },

        removeSkeletonAnimation(e) {
            e.target.parentNode.classList.remove('skeleton-animation')
            e.target.parentNode.style.height = ''
        },

        updateScreenBodyWidth() {
            store.commit(mutations.SET_SCREEN_WIDTH, window.innerWidth)
        },

        openLeftSidebar() {
            this.isVisibleSidebars.left = true
        },

        closeLeftSidebar() {
            this.isVisibleSidebars.left = false
        },

        openRightSidebar() {
            this.isVisibleSidebars.right = true
        },

        closeRightSidebar() {
            this.isVisibleSidebars.right = false
        },

        checkIfShowBackButton() {
            const isHomePage = this.$route.path === '/'

            if (!isHomePage) {
                this.showBackButton = true
            }
        },
        goBack() {
            const previousUrl = document.referrer; // Получаем предыдущий URL

            if (previousUrl.includes(window.location.host)) {
                window.history.back()
            } else {
                window.location.href = Routing.generate('homepage')
            }
        },
    },

    beforeMount() {
        if (this.isCropperExist()) {
            const existingCroppedImage = document.getElementById('slide-cropped-image').dataset.slideCroppedImage
            const existingCroppedImageOrigin = document.getElementById('slide-cropped-image').dataset.slideCroppedImageOrigin
            const existingCroppedImageData = document.getElementById('slide-cropped-image').dataset.slideCroppedImageData

            store.commit(mutations.SET_CROPPED_IMAGE, existingCroppedImage)
            store.commit(mutations.SET_CROPPED_IMAGE_ORIGIN, existingCroppedImageOrigin)
            store.commit(mutations.SET_CROPPED_IMAGE_DATA, existingCroppedImageData)
        }

        if (this.isSliderExist()) {
            const { sliderJson } = document.getElementById('slider-json').dataset
            store.commit(mutations.SET_SLIDES, sliderJson)
        }
    },
    mounted() {
        this.checkIfShowBackButton()

        store.dispatch(actions.LOAD_ALL_PRODUCTS)

        // Получение суммы доставки, если есть адрес
        this.setDeliveryPrice()

        // после загрузки вызывается метод removeSkeletonAnimation, который подчищает инлайн стили
        this.addSkeletonToCards()

        if (this.isCropperExist()) {
            // инициализация select на страничке кроппера
            const { slideClass } = document.getElementById('slide-cropped-image').dataset
            switch (slideClass) {
                case 'Model\\Slider\\ArticleSlide':
                    this.slideLinkType = 'article'
                    document.getElementById('slide-button-link-type-article').setAttribute('selected', 'selected')
                    break
                case 'Model\\Slider\\ArticleTypeSlide':
                    this.slideLinkType = 'articleType'
                    document.getElementById('slide-button-link-type-articletype').setAttribute('selected', 'selected')
                    break
                case 'Model\\Slider\\BrandSlide':
                    this.slideLinkType = 'brand'
                    document.getElementById('slide-button-link-type-brand').setAttribute('selected', 'selected')
                    break
                case 'Model\\Slider\\ProductSlide':
                    this.slideLinkType = 'product'
                    document.getElementById('slide-button-link-type-product').setAttribute('selected', 'selected')
                    break
                case 'Model\\Slider\\TagSlide':
                    this.slideLinkType = 'tag'
                    document.getElementById('slide-button-link-type-tag').setAttribute('selected', 'selected')
                    break
                case 'Model\\Slider\\CustomSlide':
                    this.slideLinkType = 'custom'
                    document.getElementById('slide-button-link-type-custom').setAttribute('selected', 'selected')
                    break
                case '':
                    this.slideLinkType = 'product'
                    document.getElementById('slide-button-link-type-product').setAttribute('selected', 'selected')
                    break
                default:
                    throw new Error('mounted: Неизвестный класс слайда')
            }

            let { slideStyle } = document.getElementById('slide-cropped-image').dataset
            if (slideStyle) {
                slideStyle = JSON.parse(document.getElementById('slide-cropped-image').dataset.slideStyle)
                if (slideStyle.descriptionAlignRight && slideStyle.descriptionAlignBottom) {
                    document.getElementById('slide-description-align-bottom-right').setAttribute('selected', 'selected')
                } else if (slideStyle.descriptionAlignBottom) {
                    document.getElementById('slide-description-align-bottom-left').setAttribute('selected', 'selected')
                } else if (slideStyle.descriptionAlignRight) {
                    document.getElementById('slide-description-align-top-right').setAttribute('selected', 'selected')
                } else {
                    document.getElementById('slide-description-align-top-left').setAttribute('selected', 'selected')
                }
            }
        }

        store.dispatch(actions.CLEAR_PRODUCTS_ON_CATALOG_UPDATE)

        // Page with `ref="productInfo"` element
        const productInfoRef = this.$refs.productInfo
        if (productInfoRef) {
            store.dispatch(
                actions.LOAD_VARIANTS,
                productInfoRef.dataset.vueProductId,
            )

            store.commit(mutations.RESET_PRODUCT_PARAMETERS)
        }

        // Page with `ref="products"` element
        const productsRef = this.$refs.products
        if (productsRef) {
            const priceFilter = getPriceFilter()
            store.commit(
                mutations.UPDATE_FILTER_FROM_PRICE,
                priceFilter ? priceFilter.from : this.$store.state.filters.price.min,
            )
            store.commit(
                mutations.UPDATE_FILTER_TO_PRICE,
                priceFilter ? priceFilter.to : this.$store.state.filters.price.max,
            )

            const optionFilter = getOptionsFilter()
            store.commit(mutations.UPDATE_FILTER_OPTIONS, optionFilter)

            store.dispatch(actions.LOAD_OPTIONS_FILTERS)
            store.dispatch(actions.LOAD_PRICE_RANGE)
        }

        // Page with `ref="cart"` element
        const cartRef = this.$refs.cart
        if (cartRef) {
            store.dispatch(actions.LOAD_QUESTIONS)

            // workaround!
            // store.commit(mutations.UPDATE_CUSTOMER_ONLINE_PAYMENT, false)
        }

        this.imgPath = this.$el.getAttribute('data-img-path').replace(/IMAGE_PATH/g, '')
        this.setIsUsingShopLogic(this.$el.hasAttribute('data-using-shop-logic'))

        Vue.prototype.tokenDaData = this.$el.getAttribute('data-api-token-da-data')

        store.dispatch(actions.LOAD_DELIVERY_INTERVALS)
        store.dispatch(actions.LOAD_PICKUP_POINTS)
        store.dispatch(actions.LOAD_CITIES)
        store.dispatch(actions.LOAD_BONUS_SYSTEM)
        store.dispatch(actions.LOAD_SHOP_CONTACTS)

        // Обновление переменной ширины экрана и подписка на это событие
        this.updateScreenBodyWidth()
        window.addEventListener('resize', this.updateScreenBodyWidth)

        autosize(document.querySelectorAll('textarea'))
        const getIdPickup = (event, iframeId) => {
            const iframe = document.getElementById(iframeId)
            const url = new URL(iframe.src)
            if (event.origin === url.origin) {
                store.dispatch(actions.CHOOSE_PICKUP_POINT_ON_MAP, event.data)
            }
        }
        if (this.isPickupMapModalExist()) {
            window.addEventListener('message', function (event) {
                getIdPickup(event, 'pickup-iframe-map-modal')
            })
        }

        if (this.isPickupMapCreateOrder()) {
            window.addEventListener('message', function (event) {
                getIdPickup(event, 'pickup-iframe-map-create-order')
            })
        }

        if (this.isCropperExist()) {
            this.previewSlide()
            const id = this.isSlideExist() ? document.getElementById('slide-id').dataset.slideId : 0
            this.updateSlideLinkOptions(id)
        }

        document.querySelector('body').addEventListener('click', this.clearLoadedAddresses)

        // страница профиля, сделать 1й таб активным
        const profileDefaultOpenedTab = document.getElementById('defaultOpen')

        if (profileDefaultOpenedTab) {
            profileDefaultOpenedTab.click()
            document.querySelector('.profile__navigation-tab').classList.add('profile__navigation-tab_active')
        }
    },
    created() {
        if (!this.isAuthenticated
            && (window.location.pathname === '/profile'
                || window.location.pathname === '/profile/account'
                || window.location.pathname === '/profile/orders'
                || window.location.pathname.match(/^\/profile\/order\/\d+$/)
                || window.location.pathname === '/profile/bonus')) {
            window.location.href = Routing.generate('homepage')
        }

        // Заполняем данные формы, если клиент авторизован
        if (this.isAuthenticated) {
            this.fillDetailsFromAuth()
        }
    },
    watch: {
        // Получение суммы доставки, если в корзине поменялось значение
        basketSum() {
            this.setDeliveryPrice()
        },
        deliveryPrice: (newValue) => {
            store.commit(mutations.UPDATE_ORDER_DETAILS_DELIVERY_PRICE, newValue)
        },
        customerPickupPoint: (newValue, oldValue) => {
            if (newValue) {
                store.dispatch(actions.CHOOSE_ADDRESS_PICKUP, newValue)
            }
            store.commit(mutations.UPDATE_PICKUP_POINTS_SELECTED, newValue)
        },
        isAuthenticated() {
            if (this.isAuthenticated) {
                this.fillDetailsFromAuth()
                this.getCurrentBuyerBonuses()
            }
        }
    },
})

window.vueApp = vueApp
if (process.env && process.env.NODE_ENV === 'development' && window.__VUE_DEVTOOLS_GLOBAL_HOOK__) {
    // eslint-disable-next-line no-underscore-dangle
    window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = vueApp.constructor
    Vue.config.devtools = true
}
